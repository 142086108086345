export const SET_SHIPMENT_TYPE = 'SET_SHIPMENT_TYPE'
export const setShipmentType = (shipmentType) => ({type: 'SET_SHIPMENT_TYPE', shipmentType})

export const SET_AVAILABLE_BOOKING_DATES = 'SET_AVAILABLE_BOOKING_DATES'
export const setAvailableBookingDates = (availableBookingDates) => ({type: SET_AVAILABLE_BOOKING_DATES, availableBookingDates})


export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';

export const setSelectedCountry = (countryCode) => ({
  type: SET_SELECTED_COUNTRY,
  payload: countryCode,
});