import { LOCALES } from '../locales'

const traductions = {
    [LOCALES.DEUTCH]: {
        "home_title": "IHRE BESTELLUNG ZURÜCKGEBEN ODER UMTAUSCHEN",
        "home_title_2": "RÜCKSENDUNGS- UND UMTAUSCHBEDINGUNGEN",
        "home_title_non_eu": "IHRE BESTELLUNG ZURÜCKGEBEN",
        "home_title_2_non_eu": "RÜCKSENDUNGSBEDINGUNGEN",
        "home_1": "Rücksendungen und Umtausch sind innerhalb von 14 Tagen nach Erhalt des Pakets möglich und sind in Frankreich kostenlos.",
        "home_2": "Geben Sie einfach unten Ihre E-Mail oder Telefonnummer sowie Ihre Bestellnummer ein.",
        "home_3": "Grundsätzlich können nur Artikel zurückgegeben werden, die sich in demselben Zustand befinden, in dem sie geliefert wurden. Das heißt, dass die Artikel keine Gebrauchsspuren aufweisen, nicht beschädigt, in irgendeiner Weise verändert und auch nicht gewaschen sein dürfen. Also sei vorsichtig, wenn du die Sachen anprobierst. Außerdem solltest du die Artikel in der Originalverpackung mit dem Rücksendeschein, allen Etiketten und auch der Kraftpapierverpackung zurückschicken. Wenn du gut mit den Produkten umgehst, können wir sie an jemand anderen weitergeben. Dann gibt es keine Abfälle und alle sind zufrieden.",
        "home_4": " ",
        "home_4_1": " ",
        "home_4_2": " ",
        "login": "Deine Kontaktdaten",
        "phone": "Telefonnummer",
        "email_phone": "E-Mail/Telefon",
        "order_number": "Bestellnummer",
        "order": "Bestellung",
        "login_comment": "*Wenn du Artikel aus derselben Bestellung ein zweites Mal zurücksendest, schreibe bitte RR vor die Bestellnummer.",
        "submit": "Bestätigen",
        "logout": "Startseite",
        "return": "Zurück",
        "cancel": "Abbrechen",
        "next": "Als Nächstes",
        "return_login": "Zurück zum Login",
        "select_order": "Wähle eine Bestellung aus",
        "required": "Pflichtfeld",
        "loading": "Lädt",
        "invalid_email": "Ungültige E-Mail Adresse",
        "email_input": "Gib deine E-Mail-Adresse ein, um eine Bestätigungsnachricht zu erhalten",
        "already_generated_returns": "Du hast deine Rücksendeunterlagen bereits angefordert",
        "download_returns": "Rücksendeunterlagen herunterladen",
        "request_received": "Bestätigung deines Umtauschs / deiner Rücksendung",
        "request_received_info_1": "Vielen Dank, wir haben deine Umtausch- oder Rücksendenanfrage erhalten!",
        "request_received_info_2": "In wenigen Minuten erhältst du eine E-Mail mit deinem Rücksendeetikett und dem Dokument, das du deinem Paket beilegen musst.",
        "request_received_info_3": "Sobald dein Paket zurückgeschickt wurde, dauert die Bearbeitung 2-3 Wochen. Du erhältst eine E-Mail-Benachrichtigung, sobald es bestätigt wurde.",
        "request_received_info_4": "Die Rückerstattung erfolgt je nach Bank unterschiedlich schnell, spätestens aber innerhalb von 10 Werktagen nach Erhalt der oben genannten E-Mail-Benachrichtigung.",
        
        "request_received_non_eu": "Bestätigung deiner Rücksendung",
        "request_received_info_1_non_eu": "Vielen Dank, wir haben deine Rücksendenanfrage erhalten!",
        "request_received_info_2_non_eu": "In wenigen Minuten erhältst du eine E-Mail mit dem Dokument, das du deinem Paket beilegen musst. Bitte beachte, dass die Versandkosten von dir getragen werden müssen. Wir empfehlen dir, das Paket per Fedex oder DHL zu versenden, um eine Sendungsverfolgung zu haben.",
        "request_received_info_3_non_eu": "Sobald dein Paket zurückgeschickt wurde, dauert die Bearbeitung 2-3 Wochen. Du erhältst eine E-Mail-Benachrichtigung, sobald es bestätigt wurde.",
        "request_received_info_4_non_eu": "Die Rückerstattung erfolgt je nach Bank unterschiedlich schnell, spätestens aber innerhalb von 10 Werktagen nach Erhalt der oben genannten E-Mail-Benachrichtigung.",
        "booking_courier_error": "Es gab einen Fehler bei der Buchung deiner Rücksendung",
        "booking_courier_confirm": "Du hast soeben eine Bestätigung für deine Rücksendung erhalten",
        "booking_courier_issues": "Wir haben derzeit Probleme mit der Buchung deiner Rücksendung. Bitte versuche es in ein paar Minuten noch einmal. Sollte das Problem weiterhin bestehen, schick uns bitte eine E-Mail an support@asphalte-paris.com",
        "try_again": "Nochmal versuchen",
        "return_place": "Wohin sollen wir deine umgetauschten Artikel schicken?",
        "address": "Adresse",
        "city": "Stadt",
        "postal_code": "Postleitzahl",
        "country": "Land",
        "item_exchange_by": "Dein Artikel wird umgetauscht mit",
        "item_returned": "Dein Artikel wird zurückgeschickt",
        "item_not_received": "Du hast deine Sendung nicht erhalten",
        "select_items": "Wähle die Artikel deiner Bestellung aus, die du zurückgeben möchtest.",
        "select_items_non_eu": "Wähle die Artikel deiner Bestellung aus, die du zurückgeben möchtest",
        "confirm_return": "Rückgabe bestätigen",
        "book_courier": "Rücksendung buchen",
        "exchange_reason": "Was ist der Grund für den Umtausch?",
        "return_reason": "Was ist der Grund für die Rücksendung?",
        "selected_reason": "Ausgewählter Grund:",
        "too_big": "Zu groß",
        "too_small": "Zu klein",
        "color_is_not_suitable": "Die Farbe gefällt mir nicht",
        "cut_is_not_suitable": "Die Passform ist nichts für mich",
        "material_is_not_suitable": "Das Material gefällt mir nicht",
        "ordered_two_different_sizes": "Ich habe 2 verschiedene Größen bestellt",
        "ordered_two_different_colors": "Ich habe 2 verschiedene Farben bestellt",
        "damaged_item": "Der Artikel ist beschädigt",
        "changed_mind": "In der Zwischenzeit habe ich ein ähnliches Produkt gefunden",
        "too_late": "Das Produkt kam zu spät in der Saison",
        "add_comment": "Möchtest du einen Kommentar hinzufügen?",
        "exchange_item": "Gegen welchen Artikel möchtest du diesen umtauschen?",
        "available_15days": "Innerhalb von 15 Tagen verfügbar",
        "exchange": "Umtausch",
        "exchange_not_possible": "Du kannst diesen Artikel nicht umtauschen",
        "refund": "Rückzahlung",
        "wrong_item": "Mir wurde der falsche Artikel geliefert",
        "no_order_to_return": "Auf deinem Konto befindet sich momentan keine Bestellung, die zurückgegeben werden kann",
        "order_not_found": "Die Bestellung wurde nicht gefunden",
        "order_cannot_be_returned": "Es sieht so aus, als ob deine Bestellung nicht zurückgegeben werden kann",
        "country_not_available": "Für eine Bestellung aus diesem Land kann kein Rücksendeetikett erstellt werden",
        "problem_creating_return": "Es scheint ein Problem bei der Erstellung des Rücksendeetiketts zu geben, bitte versuche es in ein paar Sekunden erneut",
        "from_which_address": "Von welcher Adresse aus möchtest du deine Artikel zurückschicken?",
        "confirm_cancel_courier": "Bist du sicher, dass du die Rücksendung stornieren möchtest?",
        "courier_cancelled": "Deine Rücksendung wurde storniert",
        "courier_cancelled_error": "Fehler beim Stornieren deines Rücksendeetiketts",
        "send_error_1": "Bitte kontaktiere uns unter",
        "send_error_2": "mit Verweis auf den Fehlercode",
        "send_error_3": "und die ID",
        "carrier_choice": "Wählen Sie Ihr Transportunternehmen",
        "pickup_on_map": "Auf einer Karte anzeigen",
        // Haut 
        "col": "Kragen",
        "epaules": "Schultern",
        "poitrine": "Brust",
        "aisselles": "Achseln",
        "ventre": "Bauch",
        "longueur_manches": "Ärmellänge",
        "largueur_manches": "Weite der Ärmel",
        // Bas
        "taille": "Taille",
        "fourche": "Hintern",
        "hanches": "Hüften",
        "cuisses": "Oberschenkel",
        "mollets": "Waden",
        "chevilles": "Knöchel",
        // Chaussures
        "talon": "Ferse",
        "orteils": "Zehen",
        "longueur_totale": "Gesamtlänge",
        "globalement_trop_large": "Insgesamt zu locker",
        "globalement_trop_serre": "Insgesamt zu eng",
        "footer_contact":"Zögern Sie nicht, uns zu kontaktieren, wenn Sie Fragen haben, unter ",
        "already_courier": "Ihre Kurieranfrage wurde erfolgreich storniert",
        "info_courier_1": "Er wird sich am ",
        "and": " und ",
        "between": " zwischen ",
        "at_this_address": " an folgender Adresse vorstellen:",
        "error": "Fehler",
        "error_page_1": "Diese Seite ist vorübergehend nicht verfügbar",
        "error_page_2": "Keine Panik, unsere Agenten sind dran",
        "not_found": "SEITE NICHT GEFUNDEN",
        "not_found_1": "Diese Seite ist nicht verfügbar",
        "no_courier_available": "Keine Kuriere mehr verfügbar",
        "eligible_for_courier": "Gute Nachrichten, Ihre Adresse ist für einen Austausch oder eine Rückgabe per Kurier geeignet",
        "not_eligible_for_courier": "Leider sind keine Termine mehr für einen Austausch oder eine Rückgabe per Kurier verfügbar, Sie können Ihre Rücksendung jedoch per Post vornehmen.",
        "poste_office": "Postweg",
        "courier": "Kurier",
        "request_return_label":"Rücksendeetikett anfordern",
        "request_return_label_non_eu":"Rücksendungsanfrage erstellen",
        "return_label_sent_email":"Ihr Rücksendeetikett wurde per E-Mail an Sie gesendet",
        "return_label_sent_email_non_eu":"Ihr Rücksendeformular wurde per E-Mail an Sie gesendet",
        "problem_creating_return": "Es scheint ein Problem bei der Erstellung des Rücksendeetiketts zu geben. Bitte versuche es in ein paar Sekunden erneut.",
        "problem_creating_return_non_eu": "Es scheint ein Problem bei der Erstellung des Rücksendeformulars zu geben. Bitte versuche es in ein paar Sekunden erneut.",
        "confirm_completed":"Sind Sie sicher, dass Sie fertig sind?",
        "info_before_complete":"Sobald validiert, werden Sie nicht mehr in der Lage sein, weitere Änderungen an Ihrer Bestellung vorzunehmen.",
        "generation_error": "Fehler bei der Erstellung Ihres Rücksendeetiketts",
        "difficult_return_label_generation":"Unser Transportunternehmen hat derzeit Schwierigkeiten bei der Erstellung Ihres Rücksendeetiketts. Bitte versuchen Sie es in einigen Momenten erneut. Wenn das Problem weiterhin besteht, zögern Sie nicht, uns eine E-Mail an sav@sympl.fr zu senden",
        "difficult_return_label_generation_non_eu":"Unser Transportunternehmen hat derzeit Schwierigkeiten bei der Erstellung Ihres Rücksendeformulars. Bitte versuchen Sie es in einigen Momenten erneut. Wenn das Problem weiterhin besteht, zögern Sie nicht, uns eine E-Mail an sav@sympl.fr zu senden",
        "return_item":"Artikel zurücksenden",
        "footer_faq": "Brauchen Sie Hilfe? Mehr Details",
        "here": "hier",
        "home_3": "Für Bestellungen im Ausland oder Übersee ist das Verfahren dasselbe, aber die Rücksendung erfolgt auf Ihre Kosten.",
        "langFR":"Französisch",
        "langEN":"Englisch",
        "langDE":"Deutsch",
        "return_info_eu_1": "Rücksendungen und Umtausch sind innerhalb von 14 Tagen nach Erhalt des Pakets möglich und sind in Frankreich kostenlos.",
        "return_info_eu_2": "Ein retourniertes Produkt wird nur akzeptiert, wenn es auf unserer Website gekauft wurde und in seiner ursprünglichen Verpackung ist. Die Rücksendeprozedur kann einige Zeit dauern (zwischen 2 und 3 Wochen). Jede Kresse wird von Hand überprüft und jede Bestellung wird verarbeitet, was ein personalisiertes Rücksenden ermöglicht.",
        "return_info_eu_3": "Es ist ausreichend, unten Ihre E-Mail-Adresse oder Telefonnummer, Bestellnummer und die Bestellungsland auszuwählen.",
        "return_info_non_eu_1": "Sie haben 14 Tage, beginnend mit der Lieferungsdatum, um einen Rücksendung über unsere dedizierte Plattform zu machen (die Versandkosten sind Ihre Verantwortung). Wir empfehlen die Verwendung von FedEx oder DHL für ein verfolgtes Versand. Austausche sind nur für Bestellungen verfügbar, die von der Metropole Frankreich und Belgien bestellt wurden.",
        "return_info_non_eu_2": "Ein retourniertes Produkt wird nur akzeptiert, wenn es auf unserer Website gekauft wurde und in seiner ursprünglichen Verpackung ist. Die Rücksendeprozedur kann einige Zeit dauern (zwischen 2 und 3 Wochen). Jede Kresse wird von Hand überprüft und jede Bestellung wird verarbeitet, was ein personalisiertes Rücksenden ermöglicht.",
        "return_info_non_eu_3": "Es ist ausreichend, unten Ihre E-Mail-Adresse oder Telefonnummer, Bestellnummer und die Bestellungsland auszuwählen.",
        "home_special_UK": "Für die Rücksendungsbedingungen für Großbritannien, bitte folgen Sie",
        "here":"diesem Link",
        "home_special_UK_link": "https://www.labonnebrosse.co.uk/pages/uk-refund-policy"
    }
}

export default traductions