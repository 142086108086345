import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import RenderTextField from '../utilis/renderField/RenderTextField'
import RenderSelectField from '../utilis/renderField/RenderSelectField'

import validate from './validate'
import countryList from './countryList.json';
import { getBookingDates } from '../../api/api'
import Modal from './Modal'

import { setAvailableBookingDates, setSelectedCountry } from './actions'

import errorCatcher from '../utilis/api/errorCatcher'
import translate from '../../i18n/translate';

const Title = styled.h2`
  font-weight: 300;
  font-size: 1.2em;
  text-align: left;
`

const ReturnFormContainer = styled.div`
  @apply flex flex-col items-center justify-center mt-8;
`;

const Paragraph = styled.p`
  font-size: 14px;
  text-align: left;
  max-width: 450px;
  margin-bottom: 30px;
`
/**
* Composant contenant le fomulaire permettant à l'utilisateur de
* choisir son adresse pour le retour ou l'échange
*/

class ReturnAdress extends Component {
  state = {
    fetching: false,
    openModal: false
  }

  componentDidMount() {
    const { prefilled, recipientAddress } = this.props
    prefilled('street', recipientAddress.street)
    prefilled('city', recipientAddress.city)
    prefilled('postalCode', recipientAddress.postalCode)
    prefilled('countryCode', recipientAddress.countryCode)
  }

  submit(values) {
    const { postalCode, countryCode } = values;
    
    // Dispatch selected country on form submission
    this.props.setSelectedCountry(countryCode);
    
    this.props.history.push('/return-items');
    /*getBookingDates({ postalCode, countryCode }).then(res => {
      if (res.data.success === true) {
        this.props.setAvailableBookingDates(res.data.availableBookingDates)
        this.setState({openModal: true})
      } else {
        this.props.history.push('/return-items')
      }
    }, (error) => {
      errorCatcher(error.response.status, this.props.history)
    })*/
  }

  handleCountryChange = (event) => {
    const countryCode = event.target.value;
    this.props.setSelectedCountry(countryCode);
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Modal open={this.state.openModal}/>
        <ReturnFormContainer>
            <form onSubmit={handleSubmit(this.submit.bind(this))}>

              <Grid container spacing={0} direction="column">
                <Grid container item spacing={0} justify="center" >
                  <Grid item xs={12}>
                    <Title>
                      {translate("from_which_address")}
                    </Title>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row">
                <Grid container item spacing={0} justify="center" >
                  <Grid item xs={12}>
                    <Field id="street"
                           style={{width: '100%'}}
                           name="street"
                           label={translate("address")}
                           component={RenderTextField}
                           type="text"
                           />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row">
                <Grid container item spacing={0} justify="center" >
                  <Grid item xs={6}>
                    <Field id="city"
                           style={{width: 'calc(100% - 10px)', marginRight: '10px',marginTop: '10px'}}
                           name="city"
                           label={translate("city")}
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                  <Grid item xs={6}>
                    <Field id="postal-code"
                           style={{width: 'calc(100% - 10px)', marginLeft: '10px',marginTop: '10px'}}
                           name="postalCode"
                           label={translate("postal_code")}
                           component={RenderTextField}
                           type="text"/>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row">
                <Grid container item spacing={0} justify="center" >
                  <Grid item xs={12}>
                    <Field  id="country-code"
                            style={{width: '100%'}}
                            name="countryCode"
                            placeHolder={translate("country")}
                            dataCy="adress"
                            component={RenderSelectField}
                            items={countryList}
                            onChange={this.handleCountryChange}
                            />
                  </Grid>
                </Grid>
              </Grid>
              <div style={{textAlign: 'left', marginTop: '30px', marginBottom: '30px'}}>
                <Button style={{minWidth: '200px'}} variant="contained" color="primary" type='submit' id="button-validate-adress">
                {translate("next")}
                </Button>
              </div>

              <div>
                <Paragraph>
                  {translate("footer_faq")} <b><a style={{ color: '#000', textDecoration: 'none' }} href="https://help.labonnebrosse.com/fr-FR/articles/echange-retour-et-remboursement-85759">{translate("here")}</a></b>.
                  <br /><br />
                  <span>{translate("footer_contact")} <b><a style={{ color: '#000', textDecoration: 'none' }} href="mailto:contact@labonnebrosse.com">contact@labonnebrosse.com</a></b></span>
                </Paragraph>
              </div>
            
            </form>
        </ReturnFormContainer>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  prefilled: (field, value) =>
    dispatch(change('returnAdress', field, value)),
  setAvailableBookingDates: (bookingDate) =>
    dispatch(setAvailableBookingDates(bookingDate)),
  setSelectedCountry: (countryCode) =>
    dispatch(setSelectedCountry(countryCode))
})

const mapStateToProps = state => ({
  recipientAddress: state.returnItem.currentUserInformations.recipientAddress
})

ReturnAdress = reduxForm({
  form: 'returnAdress',
  destroyOnUnmount: false,
  validate,
})(ReturnAdress)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReturnAdress));
