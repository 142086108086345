import React, { Component } from 'react';
import RenderTextField from '../../../../utilis/renderField/RenderTextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Field , reduxForm, formValueSelector} from 'redux-form';
import { connect } from 'react-redux';
import submit from './submit';
import translate from '../../../../../i18n/translate';

class CommentStep extends Component {
  componentDidMount() {
    const { selectedCountry, returnNatureCode, returnReasonCode } = this.props;
  }

  handleSubmit = (values) => {
    const { selectedCountry, returnReasonCode, returnNatureCode } = this.props;
    return submit(values, this.props.dispatch, this.props);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <Grid container justify="center" alignItems="center" direction='column'>
           <Typography align="center" style={{fontSize:'1.4em', marginTop: 20}}>
            {translate("add_comment")}
           </Typography>
           <Field id="custom-return-reason"
                  name="customReturnReason"
                  label={translate("comment")}
                  multiline={true}
                  variant="outlined"
                  style={{width: '80%', marginTop: 20}}
                  rows="2"
                  component={RenderTextField}
                  type="text"/>
           <Button id="button-validate-comment"
                   type="submit"
                   variant="contained"
                   color="primary"
                   style={{marginTop: 40}}>
             {translate("submit")}
           </Button>
        </Grid>
      </form>
    )
  }
}

const causeStepSelector = formValueSelector('causeStep')
const commentSelector = formValueSelector('commentStep')

const mapStateToProps = (state) => {
  return {
    returnReasonCode: causeStepSelector(state, 'returnReasonCode') || 'incorrect_item',
    customReturnReason: commentSelector(state, 'customReturnReason'),
    desiredItem: state.returnItem.desiredItem,
    returnNatureCode: state.returnItem.returnNatureCode,
    index: state.returnItem.selectedItem.index,
    selectedCountry: state.returnAdress.selectedCountry
  }
}

CommentStep = reduxForm({
  form: 'commentStep'
})(CommentStep)

export default connect(mapStateToProps)(CommentStep);
