export const returnReasonCode = [
  {
    name: 'La taille est trop petite',
    name_fr: 'La taille est trop petite',
    name_gb: 'The size is too small',
    name_de: 'Die Größe ist zu klein',
    code: 'too_small'
  },
  {
    name: 'La couleur ne me convient pas',
    name_fr: 'La couleur ne me convient pas',
    name_gb: 'The color is not suitable',
    name_de: 'Die Farbe passt nicht',
    code: 'color_is_not_suitable'
  },
  {
    name: 'Article défectueux',
    name_fr: 'Article défectueux',
    name_gb: 'Damaged item',
    name_de: 'Beschädigtes Artikel',
    code: 'damaged_item'
  }/*,
  {
    name: 'Le modèle ne convient pas',
    name_fr: 'Le modèle ne convient pas',
    name_gb: 'The model is not suitable',
    name_de: 'Das Modell passt nicht',
    code: 'model_is_not_suitable'
  }*/
]