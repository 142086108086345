import React, { Component } from 'react';
import CardItem from './CardItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '../modal/Modal';
import {
  setSeletecItem,
  resetSwipeableIndex,
  openReturnModal,
  setSwitchableProduct,
  openConfirmationCarrierModal,
  openConfirmationCourierModal
} from '../actions';
import { connect } from 'react-redux';
import { getSwitchableProducts } from '../../../api/api'

import ConfirmationCarrierModal from '../confirmationCarrierModal/Modal';
import ConfirmationCourierModal from '../confirmationCourierModal/Modal';
import translate from '../../../i18n/translate';

/**
* Composant principale de la page d'échange
* ou de retour de produit
*/
class ReturnItems extends Component {
  state = {
    loadingSwitchableProducts: false,
    isDisabled: true
  }

  /**
  * @function openModal
  * @param {object} - item
  * @description ouvre la modal pour généré l'échange
  * et récupére la liste des produit avec lesquels le remplacement
  * est possible
  */
  openModal(item) {
    this.setState({ loadingSwitchableProducts: true })
    console.log(item)
    console.log(this.props)
    getSwitchableProducts({
      barcodeValue: item.ean13,
      companyExternalId: this.props.companyExternalId
    }).then((res) => {
      if (res.data.success === true) {
        this.props.setSwitchableProduct(res.data.products)
        this.setState({ loadingSwitchableProducts: false })
      } else {
        this.props.setSwitchableProduct([])
        this.setState({ loadingSwitchableProducts: false })
      }
    })
    this.props.setSeletecItem(item)
    this.props.openReturnModal()
  }

  isDisabled() {
    return this.props.commandItems.filter(e => e.returnNatureCode !== undefined).length > 0
  }

  render() {
    const { commandItems, shipmentType, openConfirmationCarrierModal, openConfirmationCourierModal, selectedCountry } = this.props;
    const isEU = ["FR", "BE", "LU"].includes(selectedCountry);

    return (
      <div>
        {
          shipmentType === 'carrier' ?
            <ConfirmationCarrierModal />
            :
            <ConfirmationCourierModal />
        }
        <Modal loadingSwitchableProducts={this.state.loadingSwitchableProducts} />
        <Grid container
          direction="row"
          justify={{ xs: 'center', md: 'left' }}
          alignItems={{ xs: 'center', md: 'left' }}
          style={{ marginTop: 40 }}>
          <h1 style={{ fontWeight: 300, color: '#000' }}>
            {translate(isEU ? 'select_items' : 'select_items_non_eu')}
          </h1>


          <Grid style={{marginBottom: 20}} container justify={{ xs: 'center', md: 'left' }} alignItems={{ xs: 'center', md: 'left' }} direction='row'>
            <Button variant="contained"
              color="primary"
              id="validate-button"
              style={{ margin: 'auto', marginTop: 10 }}
              disabled={!this.isDisabled()}
              onClick={() => {
                if (shipmentType === 'carrier') {
                  openConfirmationCarrierModal()
                } else {
                  openConfirmationCourierModal()
                }
              }}>
              {
                isEU ?
                  shipmentType === 'carrier' ? translate("request_return_label") : translate("book_courier")
                  :
                  shipmentType === 'carrier' ? translate("request_return_label_non_eu") : translate("book_courier")
              }
            </Button>

          </Grid>
          <Grid id="card-items-container"
            container
            item md={12}
            spacing={0}
            justify={{ xs: 'center', md: 'left' }}
            alignItems={{ xs: 'center', md: 'left' }}>
            {
              commandItems.map((item, index) => {
                return (
                  <Grid key={index} item md={6} xs={9} onClick={this.openModal.bind(this, item)}>
                    <CardItem item={item} id={`card-item-${index}`} />
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    commandItems: state.returnItem.commandItems,
    companyExternalId: state.returnItem.companyExternalId,
    commandId: state.returnItem.commandId,
    shipmentType: state.returnAdress.shipmentType,
    selectedCountry: state.returnAdress.selectedCountry, // Added selectedCountry
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSeletecItem: (item) => dispatch(setSeletecItem(item)),
    resetSwipeableIndex: (item) => dispatch(resetSwipeableIndex(item)),
    openReturnModal: () => dispatch(openReturnModal()),
    setSwitchableProduct: (data) => dispatch(setSwitchableProduct(data)),
    openConfirmationCarrierModal: () => dispatch(openConfirmationCarrierModal()),
    openConfirmationCourierModal: () => dispatch(openConfirmationCourierModal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnItems);
