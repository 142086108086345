import { login } from '../../../api/api'
import { SubmissionError } from 'redux-form'
import mappingErrors from '../../utilis/api/mappingErrors'
import { addUserCommands } from '../../returnItems/actions'
import { setSwipeableIndex, setLoadingAuth, storeRecipeientCriteria } from '../actions'
import errorCatcher from '../../utilis/api/errorCatcher'
import ShopifyClient from '../../../api/shopify'

/**
* @description
* Function permettant la validation asynchrone du formulaire
* de validation, ajoute les données d'authentification aux requêtes
* @param { object } values - Valeur des champs du formulaire
* @param { function } dispatch - fonction dispatch de reduxselectedCommandStep
* @param { object } props - props
*/

const asyncValidate = (values, dispatch, props) => {
  const {recipientCriteria, externalReference} = values;
  dispatch(setLoadingAuth(true))
  dispatch(storeRecipeientCriteria(recipientCriteria))
  if (!values.externalReference || !values.recipientCriteria) {
    dispatch(setLoadingAuth(false))
    throw new SubmissionError({
      externalReference: values.externalReference ? '' : 'Champs requis',
      recipientCriteria: values.recipientCriteria ? '' : 'Champs requis'
    })
  }
  return login({recipientCriteria, externalReference})
    .then(async (res) => {
      if (res.data.success === true) {
          // Load Shopify data for all commands
          const langCode = document.documentElement.lang || 'fr';
          const commandsWithShopifyData = await Promise.all(
            res.data.commands.map(async (command) => {
              const itemsWithShopify = await Promise.all(
                command.items.map(async (item) => {
                  try {
                    const shopifyProduct = await ShopifyClient.getProductDataFromSku(item.externalReference, langCode);
                    return {
                      ...item,
                      shopify: shopifyProduct
                    };
                  } catch (error) {
                    console.error('Failed to fetch Shopify data:', error);
                    return item;
                  }
                })
              );
              return {
                ...command,
                items: itemsWithShopify
              };
            })
          );

          dispatch(setSwipeableIndex(1));
          dispatch(addUserCommands(commandsWithShopifyData));
          dispatch(setLoadingAuth(false));
      } else {
        dispatch(setLoadingAuth(false))
        throw new SubmissionError({
          externalReference: mappingErrors[res.data.errorCode]
        })
      }
    }, (error) => {
      dispatch(setLoadingAuth(false))
      if (error.status === 500) {
        errorCatcher(error.response.status, props.history)
      }
      throw new SubmissionError({
        externalReference: mappingErrors[error.response.data.errorCode]
    })
  })
}

export default asyncValidate
