import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom"
import { submit } from 'redux-form'

import SwipeableViews from 'react-swipeable-views';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import Alert from './Alert';
import Confirmation from './Confirmation';

import Email from './Email';
import ExchangeAdress from './ExchangeAdress';


import { closeConfirmationCarrierModal, resetState } from '../actions'
import createReturnByCarrier from '../../utilis/api/createReturnByCarrier';
import translate from '../../../i18n/translate';

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class ConfirmationCarrierModal extends React.Component {
  state = {
    swipeableIndex: 0
  }

  componentDidUpdate(prevProps) {
    if (this.props.returnNatureCode !== prevProps.returnNatureCode) {
      this.props.returnNatureCode === 'return' ? this.handleSwipeableIndex(1) : this.handleSwipeableIndex(0)
    }
  }

  handleSwipeableIndex = (index) => {
    this.setState({
      swipeableIndex: index
    });
  };

  render() {
    const { history, successCreateReturn, resetState, closeConfirmationCarrierModal, displayConfirmationCarrierModal, submitEmailFormCarrier, submitExchangeAdressForm, returnNatureCode } = this.props;
    return (
      <div>
        <Dialog
          open={displayConfirmationCarrierModal}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <SwipeableViews index={this.state.swipeableIndex} disabled>
              <ExchangeAdress handleSwipeableIndex={this.handleSwipeableIndex}/>
              <Email handleSwipeableIndex={this.handleSwipeableIndex}/>
              <Alert/>
              <Confirmation />
          </SwipeableViews>
          {
            !successCreateReturn && returnNatureCode === 'exchange' ?
            <DialogActions>
              {
                this.state.swipeableIndex === 0 ?
                  <div>
                    <Button onClick={closeConfirmationCarrierModal} color="primary">
                    {translate("cancel")}
                    </Button>
                    <Button id='button-validate-confirmation-modal'
                            onClick={() => {
                              submitExchangeAdressForm()
                            }} color="primary">
                            {translate("next")}
                    </Button>
                  </div>
                :
                ''
              }
              {
                this.state.swipeableIndex === 1 ?
                  <div>
                    <Button onClick={() => { this.handleSwipeableIndex(0) }} color="primary">
                      {translate("return")}
                    </Button>
                    <Button id='button-validate-confirmation-modal'
                            onClick={() => { submitEmailFormCarrier() }} color="primary">
                            {translate("next")}
                    </Button>
                  </div>
                :
                ''
              }
              {
                this.state.swipeableIndex === 2 ?
                  <div>
                    <Button color="primary" onClick={() => { this.handleSwipeableIndex(1) }}>
                      {translate("return")}
                    </Button>
                    <Button id='button-validate-confirmation-modal'
                            onClick={() => {
                              this.handleSwipeableIndex(3)
                              createReturnByCarrier()
                            }} color="primary">
                            {translate("submit")}
                    </Button>
                  </div>
                :
                ''
              }
            </DialogActions>
            :
            ''
          }

          {
            !successCreateReturn && returnNatureCode === 'return' ?
            <DialogActions>
              {
                this.state.swipeableIndex === 1 ?
                  <div>
                    <Button onClick={closeConfirmationCarrierModal} color="primary">
                      {translate("cancel")}
                    </Button>
                    <Button id='button-validate-confirmation-modal'
                            onClick={() => { submitEmailFormCarrier() }} color="primary">
                            {translate("next")}
                    </Button>
                  </div>
                :
                ''
              }

              {
                this.state.swipeableIndex === 2 ?
                  <div>
                    <Button color="primary" onClick={() => { this.handleSwipeableIndex(1) }}>
                      {translate("return")}
                    </Button>
                    <Button id='button-validate-confirmation-modal'
                            onClick={() => {
                              this.handleSwipeableIndex(3)
                              createReturnByCarrier()
                            }} color="primary">
                            {translate("submit")}
                    </Button>
                  </div>
                :
                ''
              }
            </DialogActions>
            :
            ''
          }

          {
            successCreateReturn ?
            <DialogActions style={{justifyContent: 'center'}}>
              <Button id='return-to-connexion-page'
                onClick={() => {
                  resetState()
                  history.push('/')
                }}
                color="primary">
                  {translate("return_login")}
              </Button>
            </DialogActions>
            : ''
          }
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    displayConfirmationCarrierModal: state.returnItem.displayConfirmationCarrierModal,
    successCreateReturn: state.returnItem.successCreateReturn,
    shipmentType: state.returnAdress.shipmentType,
    returnNatureCode: state.returnItem.returnNatureCode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeConfirmationCarrierModal: () =>
      dispatch(closeConfirmationCarrierModal()),
    resetState: () =>
      dispatch(resetState()),
    submitEmailFormCarrier: () =>
      dispatch(submit('emailFormCarrier')),
    submitExchangeAdressForm: () =>
      dispatch(submit('exchangeAdress'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmationCarrierModal));
