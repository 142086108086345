const stage = {
  url: {
    URL_SYMPL: 'https://live.sympl.fr',
    URL_STOCK: 'https://stock.sympl.fr'
  },
  token: "1a40fb96a378fb431e757a955a2a08faa87bfa460d8375a16aefdda145428f60",
};

const prod = {
  url: {
    URL_SYMPL: 'https://live.sympl.fr',
    URL_STOCK: 'https://stock.sympl.fr'
  },
  token: "1a40fb96a378fb431e757a955a2a08faa87bfa460d8375a16aefdda145428f60",
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : stage;

export default config;