import { SubmissionError } from 'redux-form'
import { setActionItem } from '../../../actions';
import { closeReturnModal, resetSwipeableIndex, expandCard } from '../../../actions';
import translate from '../../../../../i18n/translate';
/**
* @function submit
* @param {object} value - current value
* @param {function} dispatch - redux dispatch
* @param {values} object - all form values
* @description validation du formulaiure comment step
* lors du clique sur le bouton valider
*/

function submit(value, dispatch, props) {
  const { returnReasonCode, desiredItem, customReturnReason, returnNatureCode, index, selectedCountry } = props;



  if (returnReasonCode === 'other' && !customReturnReason) {
    throw new SubmissionError({
      customReturnReason: translate("required")
    })
  } else {
    const actionItem = {
      returnReasonCode,
      desiredItem,
      customReturnReason,
      returnNatureCode,
      index
    }
    dispatch(setActionItem(actionItem))
    dispatch(closeReturnModal())
    setTimeout(() => {
      dispatch(resetSwipeableIndex())
      dispatch(expandCard())
    }, 400);
  }
}

export default submit
