import axios from "axios";

class ShopifyService {

  constructor() {
    if (!process.env.REACT_APP_SHOPIFY_URL) {
      throw new Error("Missing env var SHOPIFY_URL");
    }

    if (!process.env.REACT_APP_SHOPIFY_VERSION) {
      throw new Error("Missing env var SHOPIFY_VERSION");
    }

    if (!process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN) {
      throw new Error("Missing env var SHOPIFY_STOREFRONT_TOKEN");
    }

    this.baseUrl = `${process.env.REACT_APP_SHOPIFY_URL}/api/${process.env.REACT_APP_SHOPIFY_VERSION}/graphql.json`;
    this.headers = {
      Accept: "application/json",
      'Content-Type': 'application/json',
      'Accept-Language': 'fr',
      'X-Shopify-Storefront-Access-Token': `${process.env.REACT_APP_SHOPIFY_STOREFRONT_TOKEN}`
    };
  }

  getSearchQuery(sku) {
    return `{
        search(first: 1, query: "${sku}") {
          totalCount
          edges {
            node {
              ... on Product {
                id
                title
                productType
                images(first: 1) {
                  edges {
                    node {
                      url
                    }
                  }
                }
                variants(first:100) {
                  edges {
                    node {
                      id
                      barcode
                      sku
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                metafields(
                  identifiers: [{namespace: "product", key: "name"}, {namespace: "product", key: "version"}]
                ) {
                  namespace
                  key
                  value
                  type
                }
              }
            }
          }
        }
      }`
  }

  cleanedId(id) {
    return id.split("/").at(-1)
  }

  async getProductDataFromSku(sku, language = 'fr') {
    if (language === 'gb') {
      language = 'en'
    }
    this.headers['Accept-Language'] = language;
    try {
      const response = await axios.post(`${this.baseUrl}`, JSON.stringify({ query: this.getSearchQuery(sku, language) }),
        { headers: { ...this.headers } })


      if ((response.status == 200 && response.data && response.data.data && response.data.data.search && response.data.data.search.edges && response.data.data.search.edges.length > 0)) {
        const resProduct = response.data.data.search.edges[0].node
        const variant = resProduct.variants.edges.map(v => v.node).find(v => v.sku === sku)
        const image = resProduct.images.edges.map(i => i.node).find(i => i.url)

        let product = {
          productId: this.cleanedId(resProduct.id),
          productTitle: resProduct.title,
          productType: resProduct.productType,
          images: image ? image.url : undefined
        }
        console.log(product)

        if (variant) {
          product.variantId = this.cleanedId(variant.id)

          const size = variant.selectedOptions.find(o => o.name === "Size")
          if (size) {
            product.size = size.value
          }

          const color = variant.selectedOptions.find(o => o.name === "Color")
          if (color) {
            product.color = color.value
          }

          if (variant.selectedOptions.length > 2) {
            const option3 = variant.selectedOptions.find(o => o.name !== "Color" && o.name !== "Size")
            if (option3) {

              product.option3 = option3
            }
          }
        }

        if (resProduct.metafields && resProduct.metafields.length > 0) {
          const versionMetafield = resProduct.metafields.find(m => m && m.namespace === "product" && m.key === "version")
          if (versionMetafield) {
            product.productVersion = versionMetafield.value
          }
        }

        return product
      }

      throw new Error(`getProductDataFromSku: failed with ${response.statusText}`)

    } catch (error) {
      console.error(`getProductDataFromSku: ${error.message}`)
      throw new Error(error)
    }
  }
}

const service = new ShopifyService();

export default service;