import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import Button from '@material-ui/core/Button'
import RenderSelectFieldCommand from '../../utilis/renderField/RenderSelectFieldCommand'
import submit from './submit'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { setSwipeableIndex } from '../actions'
import translate from '../../../i18n/translate';

const ValidateButtonContainer = styled.div`
  text-align: left;
  margin-top: 85px;
`
const Title = styled.div`
  text-align: left;
  font-size: 1.4em;
  font-weight: normal;
  font-family: Wilma, sans-serif;
  margin-bottom: 30px;
`

class SelectCommand extends Component {
  getItems = () => {
    return this.props.userCommands.map((command, index) => ({
      name: command.senderCompany.shop ? command.senderCompany.shop.name : command.senderCompany.name,
      id: index,
      isReturnable: command.isReturnable,
      returnSlipUrl: command.returnVoucher ? command.returnVoucher.returnSlipUrl : null,
      exchange: command.exchange,
      items: command.items.map(item => ({
        ...item,
        description: item.shopify ? item.shopify.productTitle : item.description
      }))
    })).filter((command) => {
      if (command.isReturnable === true || command.returnSlipUrl !== null || command.exchange !== null) {
        return true;
      }
      return false;
    });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        <Title>{translate('select_order')}</Title>
        {
          this.getItems().length > 0
          ?
          <form onSubmit={handleSubmit(submit)}>
            <Field id="selected-command"
                   name="selectedCommand"
                   placeHolder={translate('order')}
                   dataCy="command"
                   style={{width: '100%'}}
                   component={RenderSelectFieldCommand}
                   items={this.getItems()}/>
            <ValidateButtonContainer>
              <Button id="validate-command-button"
                      type="submit"
                      variant="contained"
                      color="primary">
                {translate('submit')}
              </Button>
            </ValidateButtonContainer>
          </form>
          :
          <div>
            <div style={{textAlign: 'center'}}
                 id="no-command-returnable">
              {translate('no_order_to_return')}
            </div>
            <ValidateButtonContainer>
              <Button id="return-to-auth-button"
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => this.props.resetSwipeableIndex()}>
                      {translate('return')}
              </Button>
            </ValidateButtonContainer>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userCommands: state.returnItem.userCommands
  }
}

const mapDispatchToProp = (dispatch) => {
  return {
    resetSwipeableIndex: () =>
      dispatch(setSwipeableIndex(0))
  }
}

SelectCommand = reduxForm({
  form: 'selectedCommand',
})(SelectCommand)

export default connect(mapStateToProps, mapDispatchToProp)((withRouter(SelectCommand)));
