import React, { Component } from 'react';
import RenderSelectField from '../../../../utilis/renderField/RenderSelectField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { returnReasonCode } from './renderSelectItems';
import { Field , reduxForm } from 'redux-form';
import { handleChangeSwipeableIndex } from '../../../actions';
import { connect } from 'react-redux';
import validate from  './validate';
import translate from '../../../../../i18n/translate';

/**
* Composant permettant de selectioner la cause de
* du retour
*/

class CauseStep extends Component {
  componentDidMount() {
    const { selectedCountry, returnNatureCode } = this.props;
  }

  handleSubmit = (values) => {
    const { selectedCountry, handleChangeSwipeableIndex } = this.props;
    handleChangeSwipeableIndex();
  };

  render() {
    const { handleSubmit, returnNatureCode } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" alignItems="center" direction='column' >
          <Typography align="center" style={{fontSize:'1.4em', marginTop: 20}}>
            {
              returnNatureCode === 'exchange' ?
              translate("exchange_reason")
              :
              translate("return_reason")
            }
          </Typography>
          <Field dataCy="cause"
                 name="returnReasonCode"
                 style={{width: '50%', marginTop: 10}}
                 component={RenderSelectField}
                 items={returnReasonCode}
                 language={document.documentElement.lang}
                 type="text"/>
               <Button id="cause-step-button" type="submit" variant="contained" color="primary" style={{marginTop: 40}}>{translate("submit")}</Button>
           </Grid>
        </form>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    returnNatureCode : state.returnItem.returnNatureCode,
    selectedCountry: state.returnAdress.selectedCountry
  }
}

CauseStep = reduxForm({
  form: 'causeStep',
  onSubmit: (values, dispatch) => {
    dispatch(handleChangeSwipeableIndex());
  },
  validate,
})(CauseStep)

export default connect(mapStateToProps)(CauseStep);
